import { Container } from "./styles"
import { Hero } from "../Hero/Hero"
import { About } from "../About/About";
import { Contact } from "../Contact/Contact";
import { Project } from "../Project/Project";
// import FlyingLogos from "./FlyingLogos";

export function Main() {
  return (

    <Container>
      {/* <FlyingLogos /> */}
      <Hero />
      <About />
      <Project />
      <Contact />
    </Container>
  );
}