import { Container } from './styles'

import linkedin from '../../assets/linkedin.svg'
// import reactIcon from '../../assets/react-icon.svg'
// import githubIcon from '../../assets/github.svg'
// import whatsapp from '../../assets/whatsapp.svg'
// import telegram from '../../assets/telegram.svg'
// import instagramIcon from '../../assets/instagram.svg'
// import discordIcon from '../../assets/discord.png'

export function Footer() {
  return (
    <Container className="footer">
      <a href="https://bettercallveronika.com" className="logo">
        <span>www.bettercallveronika.com</span>
      </a>
      

      <div className="social-media">
        <a
          href="https://www.linkedin.com/in/bettercallveronika"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="Linkedin" />
        </a>
      </div>
    </Container>
  )
}
