import { Container } from "./styles";
import githubIcon from "../../assets/github.svg";
import DownloadApp from "../../assets/download.png";
import externalLink from "../../assets/external-link.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function Project() {
  const projects = [
    {
      title: "Project Coordination",
      tasks: [
        "Planning and organizing project schedules",
        "Collaborating with team members and stakeholders",
        "Tracking project milestones and deadlines",
        "Conducting research and preparing reports",
        "Ensuring timely completion of delivera]bles",
      ],
    },
    {
      title: "Research and Data Analysis",
      tasks: [
        "Conducting market research",
        "Compiling data and generating reports",
        "Analyzing data trends and patterns",
        "Preparing presentations and summaries",
        "Gathering information for decision-making",
      ],
    },
    {
      title: "Administrative Support",
      tasks: [
        "Email and calendar management",
        "Data entry and organization",
        "Document preparation and editing",
        "Travel arrangements and logistics",
        "Correspondence and follow-up",
      ],
    },
    // {
    //   title: "Customer Support",
    //   tasks: [
    //     "Responding to customer inquiries",
    //     "Order processing and tracking",
    //     "Managing customer databases",
    //     "Handling returns and refunds",
    //     "Providing product/service information",
    //   ],
    // },
    {
      title: "Social Media Management",
      tasks: [
        "Creating and scheduling social media posts",
        "Engaging with followers and responding to comments/messages",
        "Monitoring social media trends and competitors",
        "Analyzing social media performance",
        "Developing social media strategi]es",
      ],
    },
  ];
  return (
    <Container id="project">
      <h2>My Services</h2>
      <div className="projects">
        {projects.map((project) => (
          <ScrollAnimation animateIn="flipInX">
            <div className="project">
              {/* <header>
                <svg
                  width="50"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
                </svg>
                <div className="project-links">
                  <a href="/" target="_blank" rel="noreferrer">
                    <img src={externalLink} alt="Visit site" />
                  </a>
                </div>
              </header> */}
              <div className="body">
                <h3>{project.title}</h3>
                <p>
                  <ul>
                    {project.tasks.map((task) => (
                      <>
                        <li>{task}</li>
                        <br />
                      </>
                    ))}
                  </ul>
                </p>
              </div>
              {/* <footer>
                <ul className="tech-list">
                  <li>Wordpress</li>
                </ul>
              </footer> */}
            </div>
          </ScrollAnimation>
        ))}
      </div>
    </Container>
  );
}
