import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
// import phoneIcon from "../../assets/phone-icon.svg";
// import { Form } from "../Form/Form";

export function Contact() {
  return (
    <Container id="contact">
      <header>
        <h2>Contact</h2>

        <p>
          Ready to delegate your tasks and boost your productivity? Get in touch
          with Better Call Veronika today. Fill out our contact form or give
          us a call, and we'll be happy to discuss how our services can benefit
          you.
        </p>
        <p>
          Let us handle the details while you focus on what matters most –
          growing your business.
        </p>
      </header>
      <div className="contacts">
        <div>
          <a href="mailto:Hello@bettercallveronika.com">
            <img src={emailIcon} alt="Email" />
          </a>
          <a href="mailto:Hello@bettercallveronika.com">
            Hello@bettercallveronika.com
          </a>
        </div>
        {/* <div>
          <a href="tel:+919630576848">
            <img src={phoneIcon} alt="Phone No" />
          </a>
          <a href="tel:+919630576848">(+91) 9630576848</a>
        </div> */}
      </div>
      {/* <Form></Form> */}
    </Container>
  );
}
